<template>
  <div :class="$style.layout">
    <header :class="$style.header">
      <div :class="$style.header__container">
        <UiBaseLogo
          :layout="media.md.matches ? 'compact' : undefined"
          :class="$style.header__logo"
        />
        <UiBaseButton
          ui="tertiary"
          :size="media.md.matches ? 'sm' : 'lg'"
          :text="$t('buttons.helpCenter')"
          :class="$style.header__button"
        />
      </div>
    </header>
    <div :class="$style.layout__body">
      <div :class="$style.layout__content">
        <slot />
      </div>
      <SharedTheFooter :class="$style.layout__footer" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent } from '#imports'
import { useMediaBreakpoints } from '~/composables/useBreakpoints'

defineComponent({
  name: 'PublicLayout',
})

const media = useMediaBreakpoints()
</script>

<style lang="scss" module>
.header {
  flex: 0 0 auto;
  padding: 26px 30px 25px;
  border-bottom: 1px solid #eaeaea;
  background: #ffffff;

  @include media($to: md) {
    padding: 10px 20px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--container-width);
    margin-inline: auto;
  }

  &__logo {
    @include media($from: md) {
      width: 210px;
      margin-right: 20px;
    }

    @include media($to: md) {
      width: 34px;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fafafa;

  &__footer {
    flex: 0 0 auto;
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
  }

  &__content {
    flex: 1 1 auto;
  }
}
</style>
